<template>
  <div class="hero is-fullheight" id="login">
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="columns is-centered vcenteded">
          <div class="column is-4">
            <figure class="avatar">
              <img src="@/assets/logo.png">
            </figure>
            <p>
              Ingresa el correo electrónico con el cual te registraste para envíarte un e-mail de recuperación de cuenta.
            </p>
            <div class="box">
              <form
                @submit.prevent="forgetPassword"
              >
                <div class="field">
                  <div class="control">
                    <input class="input"
                      type="email"
                      name="email"
                      placeholder="Correo electrónico"
                      autofocus=""
                      autocomplete="off"
                      v-model="email"
                      >
                  </div>
                </div>
                <button
                :class="{'is-loading': loading}"
                class="button is-block is-info is-medium is-fullwidth"
                :disabled="isCheckEmailField || loading"
                >Enviar e-mail </button>
              </form>
            </div>
            <p>
              <router-link class="link" :to="{ name: 'login'}" >Recordé mi contraseña</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: 'ForgotPassword',
  data () {
    return {
      email: '',
      isDisabled: true,
      loading: false
    }
  },
  computed: {
    ...mapState({ authentication: state => state.authentication }),
    isCheckEmailField () {
      if (this.email) {
        return false
      }
      return true
    }
  },
  methods: {
    ...mapMutations(['setAuthenticationValue']),
    ...mapActions(['forgetPass']),
    forgetPassword () {
      this.loading = true
      const data = {
        email: this.email
      }
      this.forgetPass(data)
        .then(resp => {
          this.loading = false
          this.$router.push('/login')
        })
        .catch(err => this.$router.push(`/${err}`))
    }
  }
}
</script>
<style scoped>
  #login {
    background-color: #27384e;
  }
  .avatar {
    margin-bottom: 50px;
  }
  .login {
    align-items: center;
    justify-content: center;
  }
  .box {
    background: transparent;
    box-shadow: none;
    padding: 40px;
  }
  .box .button {
    margin-top: 50px;
    background: #2c79b6;
  }
  .hero-body {
    padding: 0rem 1.5rem !important;
  }
  .link, .link:focus, .link:active, .link:visited {
    color: #FFFFFF;
  }
  p {
    color: #FFFFFF;
  }
</style>
